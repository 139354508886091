import React from "react";
import Body from "../../common/body/Body";
import styles from "../../common/styling/styles";
import FAQCategory from "./FAQCategory";
import { Box } from "@mui/material";
import { MainText } from "../../common/texts";

const FAQs = ({ data, title = "" }) => {
  const categoriesSet = new Set(data.map((faq) => faq.category));

  const categoriesArray = Array.from(categoriesSet);

  return (
    <Box
      sx={{
        py: styles.allTen,
        backgroundColor: styles.lightGrey,
      }}
    >
      {title && (
        <Body backgroundColor="transparent" paddingY={styles.allTwo}>
          <MainText
            textVariant="h2"
            textAlignment={styles.alignStyleCCCC}
            value={title}
            fontColor={styles.darkBlue}
          />
        </Body>
      )}

      {categoriesArray.map((category, index) => (
        <Body paddingY={styles.allTwo} backgroundColor="transparent">
          <FAQCategory key={index} data={data} title={category} />
        </Body>
      ))}
    </Box>
  );
};

export default FAQs;
