import React from 'react';

import { Add, Remove } from '@mui/icons-material';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import { Box } from '@mui/material';

import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';

const faqBodyStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
  mb: -1,
};
const FAQCard = ({ data, showText, setShowText }) => {
  const handleClick = (title) => {
    setShowText((currentTitle) => {
      if (currentTitle === title) {
        return '';
      }

      return title;
    });
  };

  return (
    <Box
      sx={{
        py: 2,
        borderBottom: '1px rgba(82, 82, 82, 0.3) solid',
        backgroundColor: 'transparent',
        minHeight: '48px',
        transition:
          'min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      }}
      onClick={() => handleClick(data.title)}
    >
      <Box
        sx={{ ...faqBodyStyle, justifyContent: 'center', alignItems: 'center' }}
      >
        <Box sx={{ flex: 1, pr: 2 }}>
          <MainText
            textVariant="h6"
            value={data.title}
            fontColor={styles.darkBlue}
            textAlignment={styles.alignStyleLLLL}
            marginY={styles.allOne}
            fontWeight={600}
          />
        </Box>
        {/* {showText === data.title ? (
          <Remove sx={{ color: styles.darkBlue }} />
        ) : (
          <Add sx={{ color: styles.darkBlue }} />
        )} */}
      </Box>
      {/* {showText === data.title && ( */}
      <Box sx={{ ...faqBodyStyle, pr: 4 }}>
        <SubText
          variant="h6"
          value={data.text}
          textAlignment={styles.alignStyleLLLL}
          fontColor="grey.900"
          marginY={styles.allTwo}
          fontWeight={500}
          paddingX={styles.allTwo}
        />
      </Box>
      {/* )} */}
    </Box>
  );
};

export default FAQCard;
