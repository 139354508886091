import { Box, Divider } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styles from '../../common/styling/styles';
import { MainText } from '../../common/texts';
import MeetingRoomTextBody from './MeetingRoomTextBody';
import Body from '../../common/body/Body';

const mobileHeaderStyle = {
  display: styles.displayStyleBBNN,
};

const MeetingRoomPoint = ({ data, index }) => {
  const { title, text, images } = data;

  const alternate = (index + 1) % 2 === 0;

  const { asset, altText } = images;
  const selectImage = asset.gatsbyImageData;

  return (
    <Body paddingY={styles.allTwo}>
      <Box sx={mobileHeaderStyle}>
        <MainText fontColor={styles.darkBlue} textVariant="h2" value={title} />
      </Box>

      <Box
        sx={{
          display: styles.displayStyleBBGG,
          gridTemplateColumns: '2fr 2fr',
          columnGap: 2,
        }}
      >
        {!alternate && (
          <Box sx={{ margin: '40px', textAlign: 'center' }}>
            <GatsbyImage
              image={selectImage}
              alt={altText || ''}
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          </Box>
        )}

        <MeetingRoomTextBody title={title} text={text} />

        {alternate && (
          <Box sx={{ margin: '40px', textAlign: 'center' }}>
            <GatsbyImage
              image={selectImage}
              alt={altText || ''}
              style={{ height: '100%' }}
            />
          </Box>
        )}
      </Box>

      {index !== 3 && <Divider sx={{ my: 15, borderStyle: 'dashed' }} />}
    </Body>
  );
};

export default MeetingRoomPoint;
