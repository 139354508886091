import React, { useState } from "react";
import styles from "../../common/styling/styles";
import FAQCard from "./FAQCard";
import { Box } from "@mui/material";
import { MainText } from "../../common/texts";

const FAQCategory = ({ data, title }) => {
  const [showText, setShowText] = useState("");

  return (
    <Box sx={{ my: 2 }}>
      <MainText
        textVariant="h3"
        textAlignment={styles.alignStyleLLLL}
        value={title}
        fontColor={styles.deepGreen}
      />

      {data.map((faq, index) => {
        if (faq.category !== title) {
          return;
        }

        return (
          <FAQCard
            key={index}
            data={faq}
            setShowText={setShowText}
            showText={showText}
          />
        );
      })}
    </Box>
  );
};

export default FAQCategory;
