import { Box } from '@mui/material';
import React from 'react';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';

const lgHeaderStyle = {
  display: styles.displayStyleNNBB,
};

const MeetingRoomTextBody = ({ title, text }) => {
  return (
    <Box sx={{ margin: 'auto' }}>
      <Box sx={lgHeaderStyle}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'end',
          }}
        >
          <Box sx={{ flex: 1 }}>
            <MainText
              fontColor={styles.darkBlue}
              textVariant="h2"
              value={title}
              textAlignment={styles.alignStyleLLLL}
              paddingX={styles.allZero}
              marginY={styles.allZero}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <SubText
          variant="subtitle1"
          fontColor={styles.grey}
          textAlignment={styles.alignStyleLLJJ}
          value={<FanGoTextProcessor text={text} />}
        />
      </Box>
    </Box>
  );
};

export default MeetingRoomTextBody;
