import React from "react";
import MeetingRoomPoint from "./MeetingRoomPoint";
import { Box } from "@mui/material";
import styles from "../../common/styling/styles";

const MeetingRoomMenu = ({ data }) => {
  return (
    <Box sx={{ py: styles.allTen, background: "white" }}>
      {data.map((point, index) => (
        <MeetingRoomPoint key={index} data={point} index={index} />
      ))}
    </Box>
  );
};

export default MeetingRoomMenu;
