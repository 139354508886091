import { graphql } from 'gatsby';
import React, { useContext } from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import Separator from '../components/common/styling/Separator';
import styles from '../components/common/styling/styles';
import { TrustedBrandIcons } from '../components/PageComps/CommonComps';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import FAQs from '../components/PageComps/FAQsPageComps/FAQs';
import MeetingRoomMenu from '../components/PageComps/MeetingRoomPage/MeetingRoomMenu';
import { DataContext } from '../providers/DataProvider';

const MeetingRoomBookingPage = ({ data }) => {
  const { heroSectionBackgroundImage: imageData } = useContext(DataContext);
  const { sanityMeetingRoomPage } = data;
  const { pageHead } = sanityMeetingRoomPage;
  const { title, subTitle } = pageHead;

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="0% 50%"
        imageData={imageData}
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={subTitle}
            subTitlePlainText={true}
            titleRowSpacing={undefined}
            titlePaddingBottom={30}
            largeSubTitleSize
          />
        }
      />

      <MeetingRoomMenu data={sanityMeetingRoomPage.meetingRoomFeatures} />

      <FAQs
        data={data.sanityFaqsPage.faqs}
        title={data.sanityFaqsPage.pageHead.subTitle}
      />

      <Separator height={100} />

      <TrustedBrandIcons
        data={data.sanityCommonContent.trustedBy}
        paddingBottom={styles.allEight}
      />
    </>
  );
};

export default MeetingRoomBookingPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityMeetingRoomPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityMeetingRoomPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      pageHead {
        title
        subTitle
      }
      meetingRoomFeatures {
        title
        text: _rawText
        images {
          asset {
            gatsbyImageData(placeholder: DOMINANT_COLOR, height: 800)
          }
          altText
        }
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      trustedBy {
        title
      }
    }
    sanityFaqsPage(language: { eq: $language }) {
      pageHead {
        subTitle
      }
      faqs {
        title
        text
        category
      }
    }
  }
`;
