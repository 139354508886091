import React from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box } from '@mui/material';

import styles from '../../common/styling/styles';
import { arrow } from './animatedScrollDownButton.module.css';

const handleClickScroll = () => {
  const element = document.getElementById('trustedIconsSection');
  if (element) {
    // 👇 Will scroll smoothly to the top of the next section
    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }
};

const AnimatedScrollDownButton = () => {
  return (
    <Box sx={{ pt: '20px' }}>
      <KeyboardArrowDownIcon
        className={arrow}
        onClick={handleClickScroll}
        sx={{
          fontSize: 40,
          color: styles.white,
          textAlign: 'center',
          width: '100%',
        }}
      />
    </Box>
  );
};

export default AnimatedScrollDownButton;
